import React from "react"
import withLayout from "../layout"

import EscapeGame from "../components/EscapeGame"

const IslandEscapeGame = () => (
  <>
    <EscapeGame />
  </>
)

const customProps = {
  localeKey: "EscapeGame",
}

export default withLayout(customProps)(IslandEscapeGame)
