import React, { useEffect, useState, useRef } from "react"
import { FormattedHTMLMessage } from "react-intl"
import ImageEscapeGame from "../ImageEscapeGame"

// this component is a mess

const EscapeGame = () => {
  const [height, setHeight] = useState(0)
  const escape = useRef(null)
  const container = useRef(null)
  useEffect(() => {
    setHeight(escape.current.clientHeight)
    window.addEventListener("resize", () => {
      setHeight(escape.current && escape.current.clientHeight)
    })
    const div = document.createElement("div")
    div.id = "vello-wizard"
    container.current.appendChild(div)
    ;(function (d, s, i, c, j, a) {
      a = div
      if (d.getElementById(i)) return
      j = d.createElement(s)
      j.id = i
      j.async = 1
      j.setAttribute("data-c", c)
      j.src = "https://static.vello.fi/js/wizard/vwiz.js"
      a.parentNode.insertBefore(j, a)
    })(document, "script", "vello-wizard-sdk", "kotasaari")
  }, [])

  return (
    <section className='section has-text-grey-dark' style={{ top: 0 }}>
      <div className='escapeContainer' style={{ height: height - 75 + "px" }}>
        <div className='escapeGame' ref={escape}>
          <ImageEscapeGame />
        </div>
      </div>
      <div className='container' ref={container}>
        <p className='mt-15'>
          <FormattedHTMLMessage id='EscapeGame_Main' />
        </p>
      </div>
    </section>
  )
}

export default EscapeGame
